import { Route, Routes, useLocation } from "react-router-dom";


import Home from "../pages/Home";
import NotFound from "../pages/NotFound";

import { AnimatePresence } from "framer-motion";

function AnimatedRoutes() {  
    const location = useLocation();
  return (
    <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" exact={true} element={<Home />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;