import React from 'react';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';

import logo from "../images/logo.png";
import branch from "../images/branch.webp";

import "../styles/home.scss";

function Home() {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25, ease: "easeOut" }}
            exit={{ opacity: 0 }}
            className='home'
        >
            <img src={branch} alt='branch' className='branch first-branch' />
            <img src={branch} alt='branch' className='branch second-branch' />
            <Link to="https://www.theknot.com/us/james-lee-and-chloe-wooliston-aug-2024"><img src={logo} alt='logo' className='logo' /></Link>
            <div className='blurb'>
                <p>Dear family and friends,</p>
                <p>We are looking forward to seeing you all on the 20th August 2024 for the ceremony to share this special day with you.</p>
                <p>In regards to our registry, we are not asking for any gifts but if you would like to contribute to our dream Honeymoon fund we would be so grateful.</p>
                <p className='text-right'>
                    <div>Lots of Love,</div>
                    <div>Chloe and Jamie</div>
                </p>
            </div>
            <Link to={"https://www.paypal.com/pools/c/96vd5wcvQD"}><button className='dontation'>Donations Here</button></Link>
        </motion.div>
    );
}

export default Home;