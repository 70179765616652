import { BrowserRouter } from "react-router-dom";

import AnimatedRoutes from "./animations/AnimatedRoutes";

import "./App.css";
import "./styles/website.scss";

function App() {

  return (
    <BrowserRouter>
      <AnimatedRoutes/>
    </BrowserRouter>
  );
}

export default App;
